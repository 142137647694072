<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="'1200px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter Items </span>
            <v-spacer></v-spacer>
            <v-alert
              v-model="alert"
              :type="type_alert"
              class="multi-line"
              width="380"
              elevation="4"
              dismissible
              >{{ message }}</v-alert
            >
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn color="blue darken-1" @click="save" class="mx-2" small>
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Fermer
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    ref="ref"
                    dense
                    v-model="item_code"
                    label="Code"
                    @focus="$event.target.select()"
                    :disabled="productload"
                    :loading="productload"
                    @input="alert = false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <listitems
                    :list="form_list"
                    :headers="article_headers"
                    :title="'Items selectionès'"
                    :master="true"
                    :add="false"
                    :del="false"
                    :Update="true"
                    :showedit="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    :multiple="false"
                    :laoding="listload"
                    @btn_click="item_delete"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";
import MVM_ITEMS_UPDATE from "../graphql/Mouvment/MVM_ITEMS_UPDATE.gql";
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
export default {
  components: {
    listitems: () => import("./ListItems.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  name: "additemform",
  props: {
    articles: Array,
    showForm: Boolean,
    depot_from: Number,
    max_field: String,
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    progress: false,
    valid: true,
    editedItem: {},
    loading: false,
    productload: false,

    form_list: [],
    prdslist: [],
    frml: 600,
    listload: false,
    item_code: null,
    article_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Code",
        value: "ref",
        selected: true,
      },
      {
        text: "label",
        value: "label",
        selected: true,
      },

      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        align: "end",
        slot: "cur",
        selected: true,
      },

      {
        text: "Origine",
        value: "origin",
        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
        type: "text",
      },
      {
        text: "",
        slot: "actions",
        selected: true,
        icon: "mdi-delete",
      },
    ],
    message: "",
    alert: false,
    type_alert: "error",
    adding: false,
  }),

  computed: {
    flist() {
      let l = [];
      l = this.prdslist.filter(
        (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
      );
      return l;
    },
    showDetail() {
      return this.showForm;
    },
    articles_list() {
      let l = [];
      this.articles.forEach((article) => {
        l.push({
          article_id: article.article_id,
          mvm_det_id: article.id,
          label: article.label,
          qte: 0,
          qte_max: article[this.max_field] ? article[this.max_field] : 0,
          products_list: [],
        });
      });
      this.form_list.forEach((element) => {
        let i = l.findIndex((elm) => elm.article_id == element.article_id);
        if (i == -1) {
          let j = this.articles.findIndex(
            (elm) => elm.article_id == element.article_id
          );
          l.push({
            mvm_det_id: this.articles[j].id,
            article_id: element.article_id,
            qte: element.qte,
            products_list: [
              {
                fk_produit_list: element.id,
                qte: element.qte,
              },
            ],
          });
        } else {
          l[i].qte = l[i].qte + element.qte;
          l[i].products_list.push({
            fk_produit_list: element.id,
            qte: element.qte,
          });
        }
      });
      return l;
    },
  },
  watch: {
    item_code: debounce(function () {
      if (this.item_code) {
        let i = this.flist.findIndex((elm) => elm.ref == this.item_code);
        if (i >= 0) {
          if (this.verif_max(this.flist[i].article_id, this.flist[i].qte)) {
            this.form_list.unshift(this.flist[i]);
            this.item_code = null;
          }
        } else {
          let j = this.form_list.findIndex((elm) => elm.ref == this.item_code);
          if (j >= 0) {
            this.message = "Item existe dans la liste ";
            this.type_alert = "warning";
            this.alert = true;
          } else {
            this.message = "Item Introuvable ";
            this.type_alert = "error";
            this.alert = true;
          }
        }
      }
    }, 1000),
  },

  created() {},
  async mounted() {
    this.$refs.form.resetValidation();
    await this.load_product_list();
  },

  methods: {
    async load_product_list() {
      //list possible

      this.productload = true;
      let v = {
        article_id: this.articles.map((elm) => elm.article_id),
        depot_id: this.depot_from,
        stock_id: 1,
      };
      let items = [];
      this.articles.forEach((element) => {
        element.colis_list.forEach((elm) => {
          items.push(elm.fk_produit_list);
        });
      });
      let r = await this.requette(PRODUCT_LIST, v);
      if (r) {
        this.prdslist = r.product_list;
        items.forEach((element) => {
          let i = this.prdslist.findIndex((elm) => elm.id == element);
          if (i >= 0) this.form_list.push(this.prdslist[i]);
        });
      }
      this.productload = false;
    },

    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async item_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation !",
          "Etes vous sur de supprimer cet item?",
          { color: "orange darken-3" }
        )
      ) {
        this.form_list.splice(item.index, 1);
      }
    },
    verif_max(article_id, qte) {
      let j = this.articles_list.findIndex(
        (elm) =>
          elm.article_id == article_id && elm.qte + qte - elm.qte_max >= qte
      );
      if (j >= 0) {
        this.message =
          "Quantité depassèe pour l'article " + this.articles_list[j].label;
        this.type_alert = "error";
        this.alert = true;
      }
      return j == -1;
    },
    async save() {
      this.save_disable = true;

      let r = await this.maj(MVM_ITEMS_UPDATE, {
        mvmdetails: this.articles_list,
      });
      if (r) {
        this.$store.dispatch("Changed", true);
        this.save_disable = false;
        this.close();
      } else {
        this.snackbar_text = " erreur d'enregistrement";
        this.snackbar_color = "error";
        this.snackbar = true;
      }
    },
  },
};
</script>
